@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

body {
  background: #36a8c5;
  background-size: 100% 100%;

  #root {
    height: 100%;
  }
}

body,
html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.Typist .Cursor {
  display: inline-block;
  font-weight: bold;
  font-size: 32px;
  color: white;

  @media (max-width: 700px) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }
}
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 0.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
