.contactContainer {
  margin-top: 5%;
  padding-left: 5%;
  color: white;
  display: flex;
  flex-direction: row;
}

.contactTitle {
  font-size: 48px;
  @media (max-width: 900px) {
    font-size: 32px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
}

.contactSubtitle {
  line-height: 4rem;
  font-size: 32px;
  @media (max-width: 900px) {
    font-size: 24px;
    line-height: 2rem;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 2rem;
  }
}

li {
  font-size: 28px;
  padding-top: 8px;
  @media (max-width: 900px) {
    font-size: 18px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.image {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7497%) hue-rotate(183deg)
    brightness(96%) contrast(103%) opacity(0.5);
  width: 20vw;
  position: absolute;
  left: calc(100% - 22vw);
  top: calc(100% - 22vw);
  overflow: hidden;
  animation: pulse 5s infinite;
  user-select: none;

  @media (max-width: 900px) {
    filter: opacity(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

.contactLink {
  text-decoration: underline;
  text-decoration-thickness: 0.12em;
  text-decoration-color: #da4201;
}

.contactLink:hover {
  text-decoration: none;
}
