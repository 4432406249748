@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap);
.styles_header__3Q_k8{width:100%;display:flex;justify-content:space-between}.styles_header__3Q_k8 .styles_moonPicture__2lYuk{-webkit-animation:styles_rotation__2WB9_ 30s infinite linear;animation:styles_rotation__2WB9_ 30s infinite linear;height:8rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}@media (max-width: 700px){.styles_header__3Q_k8 .styles_moonPicture__2lYuk{height:5rem}}@-webkit-keyframes styles_rotation__2WB9_{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}@keyframes styles_rotation__2WB9_{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}.styles_header__3Q_k8 .styles_pageLink__6qCGF{padding-right:15px;padding-left:15px;padding-top:2.5rem;white-space:nowrap}@media (max-width: 700px){.styles_header__3Q_k8 .styles_pageLink__6qCGF{white-space:normal}}.styles_header__3Q_k8 .styles_leftContent__2xAJs{margin-left:60px}@media (max-width: 900px){.styles_header__3Q_k8 .styles_leftContent__2xAJs{margin-left:20px}}.styles_header__3Q_k8 .styles_rightContent__1wuE9{margin-right:60px}@media (max-width: 900px){.styles_header__3Q_k8 .styles_rightContent__1wuE9{margin-right:20px}}.styles_header__3Q_k8 .styles_leftContent__2xAJs,.styles_header__3Q_k8 .styles_rightContent__1wuE9{display:flex;justify-content:space-between;color:white;font-weight:bold;font-size:14px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:100%}@media (max-width: 700px){.styles_header__3Q_k8 .styles_leftContent__2xAJs,.styles_header__3Q_k8 .styles_rightContent__1wuE9{font-size:10px}}

.styles_socialMediaIcon__2z7Op{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:32px;height:32px;-webkit-filter:opacity(0.5) drop-shadow(0 0 0 #384951);filter:opacity(0.5) drop-shadow(0 0 0 #384951);margin-left:0.5vw}@media (max-width: 720px){.styles_socialMediaIcon__2z7Op{width:24px;height:24px;margin-left:1vw}}

.styles_footer__1m0AP{bottom:0%;position:fixed;width:100%;margin-bottom:2%;margin-left:5%;display:flex;align-items:center}

.styles_body__2TlJG{margin-top:5%;width:50%;padding-left:5%}.styles_body__2TlJG .styles_normalText__3fD7D{font-weight:normal;color:white;font-size:40px}@media (max-width: 700px){.styles_body__2TlJG .styles_normalText__3fD7D{font-size:24px}}@media (max-width: 500px){.styles_body__2TlJG .styles_normalText__3fD7D{font-size:16px}}.styles_body__2TlJG .styles_boldText__1UGKf{text-decoration:none;font-weight:bold;color:white;font-size:40px}@media (max-width: 700px){.styles_body__2TlJG .styles_boldText__1UGKf{font-size:24px}}@media (max-width: 500px){.styles_body__2TlJG .styles_boldText__1UGKf{font-size:16px}}.styles_body__2TlJG .styles_profileLink__2RnY6{font-weight:bold;text-decoration:underline;text-decoration-thickness:0.15em;-webkit-text-decoration-color:#da4201;text-decoration-color:#da4201}.styles_body__2TlJG .styles_profileLink__2RnY6:hover{text-decoration:none}.styles_body__2TlJG .styles_underscored__35o1y:hover{text-decoration:none}

.styles_contactContainer__XLEwX{margin-top:5%;padding-left:5%;color:white;display:flex;flex-direction:row}.styles_contactTitle__3BEsJ{font-size:48px}@media (max-width: 900px){.styles_contactTitle__3BEsJ{font-size:32px}}@media (max-width: 500px){.styles_contactTitle__3BEsJ{font-size:24px}}.styles_contactSubtitle__2P-sz{line-height:4rem;font-size:32px}@media (max-width: 900px){.styles_contactSubtitle__2P-sz{font-size:24px;line-height:2rem}}@media (max-width: 500px){.styles_contactSubtitle__2P-sz{font-size:16px;line-height:2rem}}li{font-size:28px;padding-top:8px}@media (max-width: 900px){li{font-size:18px}}@media (max-width: 500px){li{font-size:14px}}.styles_image__1mxYx{-webkit-filter:brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7497%) hue-rotate(183deg) brightness(96%) contrast(103%) opacity(0.5);filter:brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7497%) hue-rotate(183deg) brightness(96%) contrast(103%) opacity(0.5);width:20vw;position:absolute;left:calc(100% - 22vw);top:calc(100% - 22vw);overflow:hidden;-webkit-animation:styles_pulse__2oBS2 5s infinite;animation:styles_pulse__2oBS2 5s infinite;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}@media (max-width: 900px){.styles_image__1mxYx{-webkit-filter:opacity(0);filter:opacity(0)}}@-webkit-keyframes styles_pulse__2oBS2{0%{-webkit-transform:scale(0.95);transform:scale(0.95)}50%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(0.95);transform:scale(0.95)}}@keyframes styles_pulse__2oBS2{0%{-webkit-transform:scale(0.95);transform:scale(0.95)}50%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(0.95);transform:scale(0.95)}}.styles_contactLink__2GWbA{text-decoration:underline;text-decoration-thickness:0.12em;-webkit-text-decoration-color:#da4201;text-decoration-color:#da4201}.styles_contactLink__2GWbA:hover{text-decoration:none}

.styles_profilePic__2_uWD{height:200px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border-radius:100px;margin-bottom:2%}@media (max-height: 900px){.styles_profilePic__2_uWD{height:150px;border-radius:75px}}@media (max-height: 500px){.styles_profilePic__2_uWD{height:100px;border-radius:50px}}.styles_aboutContainer__2G02i{display:flex;flex-direction:column;justify-content:center;align-items:center;color:white;font-size:32px;font-weight:bold;line-height:1.5rem;text-align:center}@media (max-height: 900px){.styles_aboutContainer__2G02i{font-size:18px;line-height:1.3rem}}@media (max-width: 500px){.styles_aboutContainer__2G02i{font-size:14px;line-height:1.2rem}}.styles_aboutLink__2BgFE{text-decoration:underline;text-decoration-thickness:0.15em;-webkit-text-decoration-color:#da4201;text-decoration-color:#da4201}.styles_aboutLink__2BgFE:hover{text-decoration:none}

.styles_notFoundContainer__1IfMn{padding-top:5vh;display:flex;flex-direction:column;text-align:center}.styles_notFoundContainer__1IfMn a{color:white;text-decoration:underline;-webkit-text-decoration-color:#da4201;text-decoration-color:#da4201;text-decoration-thickness:0.15em;font-size:14px}@media (min-width: 768px){.styles_notFoundContainer__1IfMn a{font-size:16px}}@media (min-width: 1024px){.styles_notFoundContainer__1IfMn a{font-size:24px}}.styles_errorTitle__27YAe{margin:0 auto 1.875rem;color:white;font-size:104px;line-height:0.8}@media (min-width: 768px){.styles_errorTitle__27YAe{font-size:160px}}@media (min-width: 1024px){.styles_errorTitle__27YAe{font-size:200px}}.styles_errorDescription__1Nm-D{color:white;font-size:24px;margin:0 auto 8px;line-height:1.2}@media (min-width: 768px){.styles_errorDescription__1Nm-D{font-size:32px}}@media (min-width: 1024px){.styles_errorDescription__1Nm-D{font-size:40px}}.styles_errorText__3DsO7{color:white;line-height:1.5;margin:0 auto 1.125rem;font-size:12px}@media (min-width: 768px){.styles_errorText__3DsO7{font-size:16px}}@media (min-width: 1024px){.styles_errorText__3DsO7{font-size:24px}}

*{font-family:'Montserrat', sans-serif;box-sizing:border-box}a,a:visited{color:inherit;text-decoration:none}body{background:#36a8c5;background-size:100% 100%}body #root{height:100%}body,html{margin:0;height:100%;overflow:hidden}.Typist .Cursor{display:inline-block;font-weight:bold;font-size:32px;color:white}@media (max-width: 700px){.Typist .Cursor{font-size:24px}}@media (max-width: 500px){.Typist .Cursor{font-size:16px}}.Typist .Cursor--blinking{opacity:1;-webkit-animation:blink 0.5s linear infinite;animation:blink 0.5s linear infinite}@-webkit-keyframes blink{0%{opacity:1}50%{opacity:0}100%{opacity:1}}@keyframes blink{0%{opacity:1}50%{opacity:0}100%{opacity:1}}

