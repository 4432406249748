.socialMediaIcon {
  user-select: none;
  width: 32px;
  height: 32px;
  filter: opacity(0.5) drop-shadow(0 0 0 #384951);
  margin-left: 0.5vw;
  @media (max-width: 720px) {
    width: 24px;
    height: 24px;
    margin-left: 1vw;
  }
}
