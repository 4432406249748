.profilePic {
  height: 200px;
  user-select: none;
  border-radius: 100px;
  margin-bottom: 2%;
  @media (max-height: 900px) {
    height: 150px;
    border-radius: 75px;
  }
  @media (max-height: 500px) {
    height: 100px;
    border-radius: 50px;
  }
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5rem;

  text-align: center;

  @media (max-height: 900px) {
    font-size: 18px;
    line-height: 1.3rem;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 1.2rem;
  }
}

.aboutLink {
  text-decoration: underline;
  text-decoration-thickness: 0.15em;
  text-decoration-color: #da4201;
}

.aboutLink:hover {
  text-decoration: none;
}
