.header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .moonPicture {
    animation: rotation 30s infinite linear;
    height: 8rem;
    user-select: none;
    @media (max-width: 700px) {
      height: 5rem;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .pageLink {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 2.5rem;
    white-space: nowrap;

    @media (max-width: 700px) {
      white-space: normal;
    }
  }

  .leftContent {
    margin-left: 60px;
    @media (max-width: 900px) {
      margin-left: 20px;
    }
  }

  .rightContent {
    margin-right: 60px;
    @media (max-width: 900px) {
      margin-right: 20px;
    }
  }

  .leftContent,
  .rightContent {
    display: flex;
    justify-content: space-between;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: fit-content;
    height: 100%;

    @media (max-width: 700px) {
      font-size: 10px;
    }
  }
}
